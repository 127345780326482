import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [fixed, setFixed] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
    setFixed(scrollY > 72);
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="navi-002-01 navi_custom two_row_sticky-navi__8HtZT">
      <header
        className="clearfix"
        role="banner"
        id="navigations"
        style={{
          backgroundColor: "#ffffff",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div className="inner">
          <div className="navigation-cont clearfix">
            <div className="logo">
              <div>
                <Link to="/" onClick={toTop}>
                  <img
                    src="https://ferret-one.akamaized.net/images/634f42e4c174a1397836e8f9/original.png?utime=1666138852"
                    alt="オフショア開発・テストサービス、ソフトウェア開発までおまかせ｜株式会社コウェル"
                    width={401}
                    height={96}
                  />
                </Link>
              </div>
            </div>
            <div className="cont">
              <div className="row row-1">
                <div
                  className="col col-1 widthAuto_col"
                  style={{ width: "47%" }}
                />
                <div className="col col-2 hd_2btn_col" style={{ width: "53%" }}>
                  <div className="component component-1 btn_custom01 button">
                    <div className="button-cont center">
                      <Link
                        to="/contact"
                        data-hu-event="お問い合わせ"
                        data-hu-actiontype="click"
                        data-hu-label="ヘッダ"
                        className="center size-s blue head_contact"
                      >
                        お問い合わせ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav
            className="main-menu navigation-hide-border navigation-font-family pc styles_hide-border__StoOO"
            style={{
              fontFamily: "",
              height: "",
              minHeight: "",
              padding: "",
              margin: "",
              border: "",
              overflow: "",
            }}
          >
            <ul className="clearfix">
              <li className="level-01-trigger" onClick={toTop}>
                <Link to="/">家</Link>
              </li>
              <li className="level-01-trigger">
                <Link to="/about" onClick={toTop}>
                  コウェルについて
                </Link>
              </li>
              <li className="level-01-trigger">
                <Link to="/privacy" onClick={toTop}>
                  個人情報保護方針
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
