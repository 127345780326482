import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";
function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    tel: "",
    department: "",
    post: "",
    purpose: "",
    status: "",
    details: "",
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const errors = {};

    if (!formData.name) errors.name = "必須項目";
    if (!formData.email) errors.email = "必須項目";
    if (!formData.companyName) errors.companyName = "必須項目";
    if (!formData.tel) errors.tel = "必須項目";
    if (!formData.department) errors.department = "必須項目";
    if (!formData.purpose) errors.purpose = "必須項目";
    if (!formData.status) errors.status = "必須項目";
    if (!formData.details) errors.details = "必須項目";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("送信成功！");
    setFormData({
      name: "",
      email: "",
      companyName: "",
      tel: "",
      department: "",
      post: "",
      purpose: "",
      status: "",
      details: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="" />
      <meta property="og:description" content="" />
      <meta
        property="og:site_name"
        content="オフショア開発・テストサービス、ソフトウェア開発までおまかせ｜株式会社コウェル"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="" />
      <meta property="twitter:description" content="" />
      <meta
        property="og:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <meta
        property="twitter:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <title>お問い合わせ｜オフショア開発を高品質・低コストで実現</title>
      <meta
        name="description"
        content="株式会社コウェルのお問い合わせページです。サービスに関するお問い合わせ・料金プランのご相談のほか、ご不明点がございましたら、小さなことでもお気軽にご相談ください。お電話でも承ります。オフショア開発やエンジニア不足のお悩みは私たちにお任せください。"
      />
      <link rel="canonical" href="https://www.co-well.jp/contact" />
      <meta property="og:url" content="https://www.co-well.jp/contact" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="https://ferret-one.akamaized.net/images/5f894b21a70eed4f025b74bd/original.png?utime=1602833185"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/navi/style.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/fo-base.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <style
        className="colors-class-list"
        dangerouslySetInnerHTML={{ __html: "" }}
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        rel="stylesheet"
        data-href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        data-optimized-fonts="true"
      />
      <link
        href="/__/api/website/sites/c20081122467/base_design/style"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/global.css"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/sites/c20081122467?utime=1731391183488"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/pages/5f7288e6389f221d646b0778?utime=1731391183488"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/ctactions/66cfe8f0b464b703b7e18700?utime=1731391183488"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/rich-text-normal.css"
        rel="stylesheet"
      />
      <meta name="next-head-count" content={30} />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        data-n-p=""
      />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        data-n-p=""
      />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/5388cc77ecdcb32d.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/5388cc77ecdcb32d.css"
      />
      <noscript data-n-css="" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
      />
      <div id="__next" data-reactroot="">
        <noscript>
          &lt;iframe title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-PXW3TJ3"
          height="0" width="0" style="display: none; visibility: hidden"
          &gt;&lt;/iframe &gt;
        </noscript>
        <div id="page-show" page_title="お問い合わせ" form_page="true" />
        <div
          id="page-analyse"
          data-id="5f7288e6389f221d646b0778"
          style={{ display: "none" }}
        />
        <div
          className="clearfix theme-fo-base v2-0 no-navi no-header no-breadcrumbs"
          id="fo-base"
        >
          <div className="wrapper clearfix none page-5f7288e6389f221d646b0778">
            <div className="contents">
              <section
                className="content-element content-1 contactform_header_sec"
                style={{ backgroundColor: "#32acc8" }}
              >
                <div className="inner">
                  <div className="row row-1">
                    <div
                      className="col col-1"
                      style={{ padding: "0px 0px 0px 0px" }}
                    >
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{ textAlign: "center" }}
                      >
                        <Link to="/" onClick={toTop}>
                          <img
                            loading="lazy"
                            id=""
                            src="https://ferret-one.akamaized.net/images/5f72c4d4fe1e8e098e35ae11/original.png?utime=1601357012"
                            alt="ヘッダーロゴ"
                            className=""
                            style={{
                              width: "auto",
                              borderRadius: 0,
                              maxWidth: "20%",
                              margin: "10px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            height={96}
                            width={401}
                            role="presentation"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-2 contactform_sec">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288e6389f221d646b0778-e71997f7eb576b94b4bd1e5e"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h1>お問い合わせ</h1>
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div
                      className="col col-1 text_col"
                      style={{ width: "50%" }}
                    >
                      <div
                        className="component component-1 richtext"
                        data-designpart="rich-text-normal"
                        data-designpart-id="642f669ce4dc09621d945ade"
                      >
                        <p>
                          <strong>
                            ※こちらのフォームへの営業はご遠慮ください。
                          </strong>
                          <br />
                          弊社へのご提案は
                          <u>
                            <strong>
                              <a href="/" target="_self">
                                こちらのフォーム
                              </a>
                            </strong>
                          </u>
                          よりお願いします。
                        </p>
                      </div>
                      <div
                        className="cta-66cfe8f0b464b703b7e18700"
                        id="cta-66cfe8f0b464b703b7e18700"
                      >
                        <div className="component component-2 cta id-032e21b08cc25f2d99ab9dd2">
                          <div className="cta-row cta-row-0">
                            <div
                              className="cta-column cta-column-0"
                              style={{ width: "95%" }}
                            >
                              <div className="component cta-component-0 image cta-image">
                                <img
                                  loading="lazy"
                                  id=""
                                  src="https://ferret-one.akamaized.net/images/66cfe9036fc44706f1a815e9/original.png?utime=1724901635"
                                  alt=""
                                  className=""
                                  style={{
                                    width: "100%",
                                    borderRadius: 0,
                                    maxWidth: "100%",
                                  }}
                                  height={500}
                                  width={1280}
                                  role="presentation"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="component component-3 text text-align-left"
                        style={{
                          padding: "0px 0px 20px 0px",
                          overflowWrap: "break-word",
                        }}
                      >
                        <em className="strong">
                          下記に当てはまる方は、本フォームよりお問い合わせください。
                        </em>
                      </div>
                      <div className="component component-4 customform_list01 richtext">
                        <ul>
                          <li>
                            <strong>サービスに関するお問い合わせ</strong>
                          </li>
                          <li>
                            <strong>料金プランのご相談</strong>
                          </li>
                          <li>
                            <strong>その他お問い合わせ</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="col col-2 formR_col"
                      style={{ backgroundColor: "#f9f9f9", width: "50%" }}
                    >
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          下記フォームにご記入ください。（1分）
                        </em>
                      </div>
                      <div className="component component-2 customform01 form_btn_custom01 form vertical">
                        <div className="form-title" />
                        <div className="show-notice notice-d801183853ba0838037e2f68" />
                        <form
                          className="contact_form"
                          id="d801183853ba0838037e2f68"
                        >
                          <fieldset className="styles_fieldset__NZO8t">
                            <div className="group 576fd8ac69702d7f23030800">
                              <label
                                className="form-label require"
                                htmlFor="576fd8ac69702d7f23030800"
                              >
                                お名前
                              </label>
                              <div className="form-cont">
                                <input
                                  type="text"
                                  name="name"
                                  value={formData.name}
                                  onChange={handleChange}
                                  defaultValue=""
                                  placeholder="例：山田　太郎"
                                  className="entry-component require"
                                  title=""
                                />
                                <br />
                                {formErrors.name && (
                                  <span className="error-message">
                                    {formErrors.name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="group 576fd8b369702d231bc60500">
                              <label
                                className="form-label require"
                                htmlFor="576fd8b369702d231bc60500"
                              >
                                メールアドレス
                              </label>
                              <div className="form-cont">
                                <input
                                  type="text"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  defaultValue=""
                                  placeholder="例：cup@vurno.blog"
                                  className="input-mail entry-component require"
                                  title="※個人のG-mailはご遠慮いただいております。企業ドメインメールアドレスをご入力ください。"
                                />
                                <br />
                                {formErrors.email && (
                                  <span className="error-message">
                                    {formErrors.email}
                                  </span>
                                )}
                                <div>
                                  ※個人のG-mailはご遠慮いただいております。企業ドメインメールアドレスをご入力ください。
                                </div>
                              </div>
                            </div>
                            <div className="group 576fd8ad69702d231bc50500">
                              <label
                                className="form-label require"
                                htmlFor="576fd8ad69702d231bc50500"
                              >
                                会社名
                              </label>
                              <div className="form-cont">
                                <input
                                  type="text"
                                  name="companyName"
                                  defaultValue=""
                                  value={formData.companyName}
                                  onChange={handleChange}
                                  placeholder="例：株式会社●●●"
                                  className="entry-component require"
                                  title=""
                                />
                                <br />
                                {formErrors.companyName && (
                                  <span className="error-message">
                                    {formErrors.companyName}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="group 64e84ab7c64afc38b18fdd41">
                              <label
                                className="form-label require"
                                htmlFor="64e84ab7c64afc38b18fdd41"
                              >
                                電話番号
                              </label>
                              <div className="form-cont">
                                <input
                                  type="text"
                                  name="tel"
                                  value={formData.tel}
                                  onChange={handleChange}
                                  defaultValue=""
                                  placeholder="半角数字+半角ハイフンで入力してください"
                                  className="entry-component require"
                                />
                                <br />
                                {formErrors.tel && (
                                  <span className="error-message">
                                    {formErrors.tel}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="group 5f98ed2be8d8590965b60542">
                              <label
                                className="form-label require"
                                htmlFor="5f98ed2be8d8590965b60542"
                              >
                                部署名
                              </label>
                              <div className="form-cont">
                                <input
                                  type="text"
                                  name="department"
                                  defaultValue=""
                                  value={formData.department}
                                  onChange={handleChange}
                                  placeholder="例：開発部"
                                  className="entry-component require"
                                  title=""
                                />
                                <br />
                                {formErrors.department && (
                                  <span className="error-message">
                                    {formErrors.department}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="group 5f98ed49e8d8590965b60909">
                              <label
                                className="form-label"
                                htmlFor="5f98ed49e8d8590965b60909"
                              >
                                役職
                              </label>
                              <div className="form-cont">
                                <input
                                  type="text"
                                  name="post"
                                  defaultValue=""
                                  value={formData.post}
                                  onChange={handleChange}
                                  placeholder="例：部長"
                                  className="entry-component"
                                  title=""
                                />
                              </div>
                            </div>
                            <div className="group 642d00a46a82e6056f6fccbb">
                              <label
                                className="form-label require"
                                htmlFor="642d00a46a82e6056f6fccbb"
                              >
                                問い合わせの目的を教えてください。
                              </label>
                              <div className="form-cont">
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name="purpose"
                                      defaultValue="ラボ開発"
                                      title=""
                                      value="ラボ開発"
                                      checked={formData.purpose === "ラボ開発"}
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="ラボ開発"
                                    />
                                    ラボ開発
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name="purpose"
                                      value="EC関連サービス"
                                      checked={
                                        formData.purpose === "EC関連サービス"
                                      }
                                      onChange={handleChange}
                                      defaultValue="EC関連サービス"
                                      title=""
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="EC関連サービス"
                                    />
                                    EC関連サービス
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name="purpose"
                                      defaultValue="ソフトウェアテストサービス"
                                      title=""
                                      value="ソフトウェアテストサービス"
                                      checked={
                                        formData.purpose ===
                                        "ソフトウェアテストサービス"
                                      }
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="ソフトウェアテストサービス"
                                    />
                                    ソフトウェアテストサービス
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      defaultValue="その他"
                                      title=""
                                      name="purpose"
                                      value="その他"
                                      checked={formData.purpose === "その他"}
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="その他"
                                    />
                                    その他
                                  </label>
                                </div>
                              </div>
                              {formErrors.purpose && (
                                <span className="error-message">
                                  {formErrors.purpose}
                                </span>
                              )}
                            </div>
                            <div className="group 5f43672002a06712384edc8d">
                              <label
                                className="form-label require"
                                htmlFor="5f43672002a06712384edc8d"
                              >
                                ご検討状況を教えてください。
                              </label>
                              <div className="form-cont">
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      defaultValue="情報収集中"
                                      title=""
                                      name="status"
                                      value="情報収集中"
                                      checked={formData.status === "情報収集中"}
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="情報収集中"
                                    />
                                    情報収集中
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      defaultValue="導入検討中"
                                      title=""
                                      name="status"
                                      value="導入検討中"
                                      checked={formData.status === "導入検討中"}
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="導入検討中"
                                    />
                                    導入検討中
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      defaultValue="打ち合わせ希望"
                                      title=""
                                      name="status"
                                      value="打ち合わせ希望"
                                      checked={
                                        formData.status === "打ち合わせ希望"
                                      }
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="打ち合わせ希望"
                                    />
                                    打ち合わせ希望
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      defaultValue="その他"
                                      title=""
                                      name="status"
                                      value="その他"
                                      checked={formData.purpose === "その他"}
                                      onChange={handleChange}
                                      className="entry-component 5413605469702d6b470a0000"
                                      data-value="その他"
                                    />
                                    その他
                                  </label>
                                </div>
                              </div>
                              {formErrors.status && (
                                <span className="error-message">
                                  {formErrors.status}
                                </span>
                              )}
                            </div>
                            <div className="group 576fd8c269702d7f23040800">
                              <label
                                className="form-label require"
                                htmlFor="576fd8c269702d7f23040800"
                              >
                                具体的なお悩み・お問い合わせ事項があればご記入ください。
                              </label>
                              <div className="form-cont">
                                <textarea
                                  placeholder="例：その他、気になる点やお問い合わせ事項がありましたらご入力ください。"
                                  className="entry-component require"
                                  title="textarea tooltip"
                                  value={formData.details}
                                  onChange={handleChange}
                                  name="details"
                                />
                                <br />
                                {formErrors.details && (
                                  <span className="error-message">
                                    {formErrors.details}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="service_term_link">
                              プライバシーポリシー{/* */}(
                              <Link
                                to="/privacy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://www.co-well.jp/privacy
                              </Link>
                              )
                            </div>

                            <div className="group">
                              <div className="form-submit">
                                <input
                                  type="submit"
                                  className="popup-link btn"
                                  value="送信する"
                                  style={{ backgroundColor: "#25809d" }}
                                  onClick={handleFormSubmit}
                                />
                              </div>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
