import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";

function Privacy() {
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content="個人情報保護方針/プライバシーポリシー"
      />
      <meta
        property="og:description"
        content="株式会社コウェルの個人情報保護の理念・方針・個人情報の取扱い（プライバシーポリシー ）についてを掲載しています。株式会社コウェルは、開発業務のお悩みを解消するサービスをオフショア開発による最適コストと安心の品質でご提供。優秀なエンジニアによる開発業務と日本人によるサポートで貴社の課題解消に貢献します。"
      />
      <meta
        property="og:site_name"
        content="オフショア開発・テストサービス、ソフトウェア開発までおまかせ｜株式会社コウェル"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="個人情報保護方針/プライバシーポリシー"
      />
      <meta
        property="twitter:description"
        content="株式会社コウェルの個人情報保護の理念・方針・個人情報の取扱い（プライバシーポリシー ）についてを掲載しています。株式会社コウェルは、開発業務のお悩みを解消するサービスをオフショア開発による最適コストと安心の品質でご提供。優秀なエンジニアによる開発業務と日本人によるサポートで貴社の課題解消に貢献します。"
      />
      <meta
        property="og:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <meta
        property="twitter:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <title>個人情報保護方針（プライバシーポリシー）｜コウェル</title>
      <meta
        name="description"
        content="株式会社コウェルの個人情報保護の理念・方針・個人情報の取扱い（プライバシーポリシー ）についてを掲載しています。株式会社コウェルは、開発業務のお悩みを解消するサービスをオフショア開発による最適コストと安心の品質でご提供。優秀なエンジニアによる開発業務と日本人によるサポートで貴社の課題解消に貢献します。"
      />
      <link rel="canonical" href="https://www.co-well.jp/privacypolicy" />
      <meta property="og:url" content="https://www.co-well.jp/privacypolicy" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="https://ferret-one.akamaized.net/images/5f894b21a70eed4f025b74bd/original.png?utime=1602833185"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/navi/style.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/fo-base.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <style
        className="colors-class-list"
        dangerouslySetInnerHTML={{ __html: "" }}
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        rel="stylesheet"
        data-href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        data-optimized-fonts="true"
      />
      <link
        href="/__/api/website/sites/c20081122467/base_design/style"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/global.css"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/sites/c20081122467?utime=1731392898454"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/pages/5f7288ed389f221d646b14a7?utime=1731392898454"
        rel="stylesheet"
      />
      <meta name="next-head-count" content={28} />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        data-n-p=""
      />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        data-n-p=""
      />
      <noscript data-n-css="" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
      />
      <div id="__next" data-reactroot="">
        <noscript>
          &lt;iframe title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-PXW3TJ3"
          height="0" width="0" style="display: none; visibility: hidden"
          &gt;&lt;/iframe &gt;
        </noscript>
        <div
          id="page-show"
          page_title="個人情報保護方針/プライバシーポリシー"
          form_page="false"
        />
        <div
          id="page-analyse"
          data-id="5f7288ed389f221d646b14a7"
          style={{ display: "none" }}
        />
        <Header />
        <div
          className="clearfix theme-fo-base v2-0 no-breadcrumbs"
          id="fo-base"
        >
          <div
            className="main-visual sub-page-custom privacy_all_box"
            style={{
              backgroundImage:
                "url(https://ferret-one.akamaized.net/images/65dc11e734ac4b0300933acd/original.jpeg?utime=1708921319)",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            <div className="overlay" />
            <div className="clearfix main-visual-content">
              <div className="row row-1">
                <div className="col col-1" style={{ width: "50%" }}>
                  <div
                    id="5f7288ed389f221d646b14a7-f33dcc78ec560f5d291b17f4"
                    className="component component-1 rich-heading heading rich-heading-align-center"
                  >
                    <h1>
                      <span className="font-size-32px">
                        個人情報保護方針
                        <br />
                        プライバシーポリシー
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="col col-2" style={{ width: "50%" }} />
              </div>
            </div>
          </div>
          <div className="wrapper clearfix none page-5f7288ed389f221d646b14a7">
            <div className="contents">
              <section className="content-element content-1">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288ed389f221d646b14a7-ea89e8f34384efaa7d893d36"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h2>個人情報保護の理念</h2>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        株式会社コウェル（以下、「当社」という）は、ITコンサルティング、オフショア開発、システム開発事業を核とした事業活動を行っています。事業活動を通じて当社が取り扱うすべての個人情報（以下、「個人情報」という）は、当社にとって大変重要な情報資産であり、その個人情報を確実に保護することは、当社の重要な社会的責務と認識しております。
                        <br />
                        したがって、当社は、事業活動を通じて取得する個人情報を、以下の方針に従って取り扱い、個人情報保護に関して、お客様および当社社員への「安心」の提供および社会的責務を果たしていきます。
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-2">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288ed389f221d646b14a7-88798e6d06cc56c20c426a4e"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h2>方針</h2>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          1．個人情報の取得・利用に関して
                        </em>
                        <br />
                        ・適法、かつ、公正な手段によって個人情報を取得いたします。
                        <br />
                        ・利用目的の達成に必要な範囲内で、個人情報を利用いたします。
                        <br />
                        ・取得した個人情報の目的外利用はいたしません。また目的外利用を行わないための措置を講じます。
                        <br />
                        ・本方針は、すべての従業者に配付して周知させるとともに、当社のホームページ、パンフレットなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。
                      </div>
                      <div
                        className="component component-3 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          2．法令、国が定める指針、その他の規範（以下、「法令等」という。）に関して
                          <br />
                        </em>
                        個人情報を取り扱う事業に関連する法令等を常に把握することに努め、当社事業に従事する従業員（以下、「従業員」という）および取引先に周知し遵守いたします。
                      </div>
                      <div
                        className="component component-4 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          3．個人情報の安全管理に関して
                          <br />
                        </em>
                        ・漏えい防止について、コンピュータ、ネットワークおよび施設、設備に適切な対策を実施いたします。
                        <br />
                        ・滅失、または、き損を確実に防止するために、災害、障害などに備えます。かつ情報のバックアップを行い、万一に備えます。
                        <br />
                        ・点検を実施し、発見された違反、事件および事故に対して、速やかにこれを是正するとともに、弱点に対する予防処置を実施いたします。
                        <br />
                        ・安全に関する教育を、従業員、取引先に徹底いたします。
                      </div>
                      <div
                        className="component component-5 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          4．苦情・相談に関して
                          <br />
                        </em>
                        お問い合わせについて、適切かつ速やかに対応いたします。
                      </div>
                      <div
                        className="component component-6 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          5．継続的改善に関して
                          <br />
                        </em>
                        ・当社の個人情報保護マネジメントシステムは、個人情報保護方針達成のため、内部規程遵守状況を監視および監査し、違反、事件、事故および弱点の発見に努め、経営者による見直しを実施いたします。これを管理策および内部規程に反映し、個人情報保護マネジメントシステムの継続的改善に努めます。
                        <br />
                        ・改善においては、法令等およびJIS Q
                        15001に準拠いたします。
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div className="col col-1" style={{ width: "50%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-left"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/5f72f5f5fe1e8e1d18357c30/original.png?utime=1601369589"
                          alt="プライバシーマーク"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "100%",
                          }}
                          height={140}
                          width={160}
                          role="presentation"
                        />
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-right"
                        style={{ overflowWrap: "break-word" }}
                      >
                        制定日　2009年2月27日
                        <br />
                        改訂日　2021年8月01日
                        <br />
                        株式会社コウェル
                        <br />
                        代表取締役社長CEO 天野 信之
                      </div>
                    </div>
                  </div>
                  <div className="row row-3">
                    <div className="col col-1" style={{ width: "50%" }} />
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div className="component component-1 richtext">
                        <p className="align-right">
                          【本方針に関する問い合わせ先】
                        </p>
                        <p className="align-right">
                          住所&nbsp;&nbsp; 〒1309 Coffeen Avenue STE
                          1200，Sheridan, Wyoming 82801
                          <br />
                          Phone&nbsp; (415) 425-9345（平日10:00 ～ 17:00）
                          <br />
                          e-mail ：p-information-counter＠co-well.jp
                          <br />
                          <span style={{ fontSize: 8 }}>
                            ※スパム防止のため＠を全角にしてあります。
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-3">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288ed389f221d646b14a7-61309f3e43e75dc648e890a2"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h2>個人情報の取り扱いについて</h2>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          １．当社が取り扱う個人情報の利用目的
                          <br />
                        </em>
                        当社は、お客様から個人情報をご提供いただく場合、あらかじめ個人情報の利用目的を明示し、その利用目的の範囲内で利用いたします。
                        <br />
                        あらかじめ明示した利用目的の範囲を超えて、お客様の個人情報を利用する必要が生じた場合は、お客様にその旨をご連絡し、お客様の同意をいただいたうえで利用いたします。
                        <br />
                        なお、個人情報の利用目的については、次のとおりとなります。
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div className="col col-1">
                      <div className="component component-1 richtext">
                        <div className="normal-table">
                          <p>
                            1）ご本人から直接書面によって取得する個人情報の利用目的
                            <br />
                            【開示対象】
                          </p>
                          <table
                            border={1}
                            cellPadding={1}
                            cellSpacing={1}
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td>分類</td>
                                <td>
                                  <p>利用目的</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  ホームページや電子メールなどによりお問い合わせいただきましたお客様情報
                                </td>
                                <td>
                                  お問い合わせ対応のため（本人への連絡も含む）
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  ホームページから資料請求いただきましたお客様情報
                                </td>
                                <td>
                                  <p>
                                    請求資料のご案内のため（本人への連絡も含む）
                                  </p>
                                  当社の各種サービスのご案内のため
                                </td>
                              </tr>
                              <tr>
                                <td>セミナーの参加者情報</td>
                                <td>
                                  セミナーのご案内のため（本人への連絡も含む）
                                  <br />
                                  当社の各種サービスのご案内のため
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  職業紹介サービスにお申込みいただきましたお客様情報
                                </td>
                                <td>
                                  <p>職業紹介サービス提供のため</p>
                                  お客様のサポートのため（本人への連絡をも含む）
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  採用支援サービスにお申込みいただきましたお客様情報
                                </td>
                                <td>
                                  <p>採用支援サービス提供のため</p>
                                  <p>
                                    お客様のサポートのため（本人への連絡をも含む）
                                  </p>
                                  ご利用履歴管理・ご請求のため
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>お客様の担当者様情報</p>
                                </td>
                                <td>
                                  当社サービスの紹介・情報提供のため
                                  <p>受注・請求などの管理のため</p>
                                  <p>受注内容確認のため（通信記録など）</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>代理店の担当者様情報</p>
                                </td>
                                <td>
                                  <p>当社サービスの紹介・情報提供のため</p>
                                  <p>当社サービスに関するサポートのため</p>
                                  <p>
                                    お問い合わせまたはご依頼などへの対応のため
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>仕入れ先、委託先の担当者様情報</p>
                                </td>
                                <td>
                                  発注・支払いなどの管理のため
                                  <p>発注内容確認のため（通信記録など）</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>当社の採用応募者情報</p>
                                </td>
                                <td>
                                  <p>就業希望者の採用選考活動のため</p>
                                  <p>本人連絡のため</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  当社の従業員情報（役員、正社員、出向者、契約社員、アルバイトなどを含む）
                                </td>
                                <td>
                                  給与管理、人事労務管理、社会保険、健康管理、および、福利厚生
                                  などのため
                                  <p>セキュリティ管理のため</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-3">
                    <div className="col col-1">
                      <div className="component component-1 richtext">
                        <p>
                          ※自己の個人情報を与えることの任意性及び当該情報を与えなかった場合にご本人に生じる結果について
                        </p>
                        <p>
                          ご自身の個人情報を弊社に提供されるか否かは、ご自身のご判断によりますが、必要な情報をご提供されない場合には、上表の各利用目的が十分に達成されないことによる不利益を蒙る可能性がありますので予めご了承ください。
                        </p>
                      </div>
                      <div className="component component-2 richtext">
                        <div className="normal-table">
                          <p>
                            2）前項以外の方法によって取得する個人情報の利用目的
                            <br />
                            【非開示対象】
                          </p>
                          <table
                            border={1}
                            cellPadding={1}
                            cellSpacing={1}
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td style={{}}>Classification</td>
                                <td style={{}}>
                                  <p>Purpose of use</p>
                                </td>
                              </tr>
                              <tr>
                                <td style={{}}>
                                  お取引先の担当者様情報
                                  <p>（パートナー企業様、派遣会社様など）</p>
                                </td>
                                <td style={{}}>業務などを依頼するため</td>
                              </tr>
                              <tr>
                                <td style={{}}>在外子会社の採用応募者情報</td>
                                <td style={{}}>
                                  プロジェクト参加候補者の確認のため
                                </td>
                              </tr>
                              <tr>
                                <td style={{}}>在外子会社の従業者情報</td>
                                <td style={{}}>
                                  日本等で実施する業務をサポートするため
                                  <p>開発などの業務を依頼するため</p>
                                </td>
                              </tr>
                              <tr>
                                <td style={{}}>
                                  業務の受託に伴い、お客様からお預かりする個人情報
                                </td>
                                <td style={{}}>
                                  <p>
                                    委託されたシステム開発・運用・保守など業務を適切に遂行するため
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p>■保有個人データに関する事項の周知</p>
                          <p>
                            当社で保有している保有個人データ又は第三者提供記録に関して、ご本人様またはその代理人様からの利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止の請求、第三者提供記録（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます。
                          </p>
                          <p>
                            ａ）事業者の名称
                            <br />
                            株式会社コウェル
                            <br />
                            1309 Coffeen Avenue STE 1200，Sheridan, Wyoming
                            82801
                            <br />
                            代表取締役 天野信之
                          </p>
                          <p>
                            ｂ）個人情報の保護管理者
                            <br />
                            管理者名： 伊藤真佐子
                            <br />
                            所属部署： 管理本部
                            <br />
                            連絡先： p-information-counter＠co-well.jp
                            <br />
                            ※スパム防止の為＠を全角にしてあります。
                          </p>
                          <p>
                            ｃ）すべての保有個人データの利用目的
                            <br />
                            “■個人情報の取り扱いについて”の“　１．当社が取り扱う個人情報の利用目的”の【開示対象】のとおりとなります。
                          </p>
                          <p>
                            ｄ）保有個人データの取り扱いに関する苦情の申し出先
                          </p>
                          <table
                            border={1}
                            cellPadding={1}
                            cellSpacing={1}
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td style={{}}>
                                  <strong>住所</strong>
                                </td>
                                <td style={{}}>
                                  〒1309 Coffeen Avenue STE 1200，Sheridan,
                                  Wyoming 82801
                                </td>
                              </tr>
                              <tr>
                                <td style={{}}>
                                  <strong>Phone</strong>
                                </td>
                                <td style={{}}>
                                  (415) 425-9345（平日10:00 ～ 17:00）
                                </td>
                              </tr>
                              <tr>
                                <td style={{}}>
                                  <strong>e-mail</strong>
                                </td>
                                <td style={{}}>
                                  <p>p-information-counter＠co-well.jp</p>
                                  <p>
                                    ※スパム防止のため＠を全角にしてあります。
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p>苦情相談窓口責任者　管理本部　伊藤 真佐子&nbsp;</p>
                          <p>
                            ｅ）当社の属する認定個人情報保護団体の名称および苦情の解決の申し出先
                          </p>
                          <p>
                            当社は、次の認定個人情報保護団体の対象事業者になっています。
                            <br />
                            認定個人情報保護団体の名称：一般財団法人
                            日本情報経済社会推進協会
                            <br />
                            苦情の解決の申し出先：認定個人情報保護団体事務局
                            <br />
                            住所：〒1309 Coffeen Avenue STE 1200，Sheridan,
                            Wyoming 82801 六本木ファーストビル内
                            <br />
                            電話番号：(415) 425-9345
                          </p>
                          <p>
                            ※個人情報の取り扱いに関する苦情のみを受付けています。
                          </p>
                          <p>
                            ｆ）保有個人データの開示等の求めに応じる手続き
                            <br />
                            1）開示等の求めの申し出先
                            <br />
                            開示等のお求めは、上記「保有個人データの取り扱いに関する苦情の申し出先」にお申し出ください。
                          </p>
                          <p>
                            ※電磁的手続きによる開示等をご希望の方は、その旨お申し出ください。原則としてご希望に沿って処理させていただきます。
                          </p>
                          <p>2）開示等の求めに関するお手続き</p>
                          <p>
                            ①お申し出受け付け後、当社からご利用いただく以下の請求書様式「保有個人データ開示請求書」を郵送いたします。
                          </p>
                          <p>
                            ②ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知ならびに開示の請求の場合のみ）を上記個人情報お問合せ係までご郵送ください。
                          </p>
                          <p>
                            ③上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうちご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報をお問い合せさせていただきます。
                          </p>
                          <p>
                            ④回答は原則としてご本人に対して書面（封書郵送）で行います。
                          </p>
                          <p>
                            3）代理人によるお求めの場合、代理人であることを確認する資料
                          </p>
                          <p>
                            開示等をお求めになる方が代理人様である場合は、代理人であることを証明する資料および代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。
                          </p>
                          <p>①代理人であることを証明する資料</p>
                          <p>
                            ＜開示等の求めをすることにつき本人が委任した代理人様の場合＞
                          </p>
                          <p>本人の委任状（原本）</p>
                          <p>
                            ＜代理人様が未成年者の法定代理人の場合＞いずれかの写し
                          </p>
                          <p>戸籍謄本</p>
                          <p>住民票（続柄の記載されたもの）</p>
                          <p>その他法定代理権の確認ができる公的書類</p>
                          <p>
                            ＜代理人様が成年被後見人の法定代理人の場合＞いずれかの写し
                          </p>
                          <p>後見登記等に関する登記事項証明書</p>
                          <p>その他法定代理権の確認ができる公的書類</p>
                          <p>②代理人様ご自身を証明する資料</p>
                          <p>運転免許証</p>
                          <p>パスポート</p>
                          <p>
                            健康保険の被保険者証（被保険者等記号・番号等を全て墨塗りしたもの）
                          </p>
                          <p>住民票</p>
                          <p>住民基本台帳カード</p>
                          <p>
                            4）利用目的の通知または開示のお求めについての手数料
                          </p>
                          <p>
                            1回のお求めにつき1,000円
                            <br />
                            （紙面でのご請求の場合は、お送りいただく請求書などに郵便為替を同封していただきます。その他の方法でご請求いただく場合は、ご請求時にご相談させていただきます。）
                          </p>
                          <p>5) 個人情報の取り扱いの委託</p>
                          <p>
                            当社は、上記の利用目的の範囲内で、個人情報の全部もしくは一部を他の事業者に委託する場合があります。
                          </p>
                          <p>ｇ）個人情報の取扱体制や講じている措置の内容</p>
                          <p>1）基本方針の策定</p>
                          <p>
                            個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等について「個人情報保護方針」を策定しています。
                          </p>
                          <p>2）個人データの取扱いに係る規律の整備</p>
                          <p>
                            取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報保護規程を策定しています。
                          </p>
                          <p>3）組織的安全管理措置</p>
                          <p>
                            ①個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。
                          </p>
                          <p>
                            ②個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施しています。
                          </p>
                          <p>4）人的安全管理措置</p>
                          <p>
                            ①個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。
                          </p>
                          <p>
                            ②個人データを含む秘密保持に関する誓約書の提出を全従業者から受けています。
                          </p>
                          <p>5）物理的安全管理措置</p>
                          <p>
                            ①個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を講じています。
                          </p>
                          <p>
                            ②個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を講じています。
                          </p>
                          <p>6）技術的安全管理措置</p>
                          <p>
                            ①アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。
                          </p>
                          <p>
                            ②個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。
                          </p>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Privacy;
