import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";

function Security() {
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="情報セキュリティ基本方針" />
      <meta
        property="og:description"
        content="株式会社コウェル（以下、当社）は、お客様からお預かりした情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。"
      />
      <meta
        property="og:site_name"
        content="オフショア開発・テストサービス、ソフトウェア開発までおまかせ｜株式会社コウェル"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="情報セキュリティ基本方針" />
      <meta
        property="twitter:description"
        content="株式会社コウェル（以下、当社）は、お客様からお預かりした情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。"
      />
      <meta
        property="og:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <meta
        property="twitter:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <title>情報セキュリティ基本方針</title>
      <meta
        name="description"
        content="株式会社コウェル（以下、当社）は、お客様からお預かりした情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。"
      />
      <link rel="canonical" href="https://www.co-well.jp/security_policy" />
      <meta
        property="og:url"
        content="https://www.co-well.jp/security_policy"
      />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="https://ferret-one.akamaized.net/images/5f894b21a70eed4f025b74bd/original.png?utime=1602833185"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/navi/style.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/fo-base.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <style
        className="colors-class-list"
        dangerouslySetInnerHTML={{ __html: "" }}
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        rel="stylesheet"
        data-href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        data-optimized-fonts="true"
      />
      <link
        href="/__/api/website/sites/c20081122467/base_design/style"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/global.css"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/sites/c20081122467?utime=1731402446531"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/pages/64e6d6e7c4def2053334597c?utime=1731402446531"
        rel="stylesheet"
      />
      <meta name="next-head-count" content={28} />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        data-n-p=""
      />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        data-n-p=""
      />
      <noscript data-n-css="" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
      />
      <div id="__next" data-reactroot="">
        <noscript>
          &lt;iframe title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-PXW3TJ3"
          height="0" width="0" style="display: none; visibility: hidden"
          &gt;&lt;/iframe &gt;
        </noscript>
        <div
          id="page-show"
          page_title="情報セキュリティ基本方針"
          form_page="false"
        />
        <div
          id="page-analyse"
          data-id="64e6d6e7c4def2053334597c"
          style={{ display: "none" }}
        />
        <Header />
        <div
          className="clearfix theme-fo-base v2-0 no-breadcrumbs"
          id="fo-base"
        >
          <div
            className="main-visual sub-page-custom privacy_all_box"
            style={{
              backgroundImage:
                "url(https://ferret-one.akamaized.net/images/65dc11e734ac4b0300933acd/original.jpeg?utime=1708921319)",
              backgroundSize: "contain",
              backgroundPosition: "center",
              height: "216px",
            }}
          >
            <div className="overlay" />
            <div className="clearfix main-visual-content">
              <div className="row row-1">
                <div className="col col-1" style={{ width: "50%" }}>
                  <div
                    id="64e6d6e7c4def2053334597c-b591f5aa106baba817c4be2d"
                    className="component component-1 rich-heading heading rich-heading-align-center"
                  >
                    <h1>
                      <span className="font-size-32px">
                        情報セキュリティ基本方針
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="col col-2" style={{ width: "50%" }} />
              </div>
            </div>
          </div>
          <div className="wrapper clearfix none page-64e6d6e7c4def2053334597c">
            <div className="contents">
              <section
                className="content-element content-1"
                style={{ padding: "0px 0px 0px 0px" }}
              >
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div className="component component-1 richtext">
                        <p>
                          株式会社コウェル（以下、当社）は、お客様からお預かりした情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。
                        </p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">1．経営者の責任</em>
                        <br />
                        当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。
                        <br />
                        <br />
                      </div>
                      <div
                        className="component component-3 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          2．社内体制の整備
                          <br />
                        </em>
                        当社は、情報セキュリティの維持及び改善のために組織を設置し、情報セキュリティ対策を社内の正式な規則として定めます。
                        <br />
                        <br />
                      </div>
                      <div
                        className="component component-4 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          3．従業員の取組み
                          <br />
                        </em>
                        当社の従業員は、情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。
                        <br />
                        <br />
                      </div>
                      <div
                        className="component component-5 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          4．法令及び契約上の要求事項の遵守
                          <br />
                        </em>
                        当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。
                        <br />
                        <br />
                      </div>
                      <div
                        className="component component-6 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="strong">
                          5．違反及び事故への対応
                          <br />
                        </em>
                        当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div className="col col-1" style={{ width: "50%" }} />
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-right"
                        style={{ overflowWrap: "break-word" }}
                      >
                        制定日 2020年9月23日
                        <br />
                        <br />
                        株式会社コウェル
                        <br />
                        代表取締役社長CEO　天野 信之
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Security;
