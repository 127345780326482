import React, { useState } from "react";

import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Footerb from "./Footerb";
function About() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="" />
      <meta property="og:description" content="" />
      <meta
        property="og:site_name"
        content="オフショア開発・テストサービス、ソフトウェア開発までおまかせ｜株式会社コウェル"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="" />
      <meta property="twitter:description" content="" />
      <meta
        property="og:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <meta
        property="twitter:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <title>コウェルについて｜オフショア開発を高品質・最適コストで実現</title>
      <meta
        name="description"
        content="当社は、オフショア開発において、確かな技術を誇る日本人エンジニアと、一流IT大学出身のベトナム人エンジニアによる開発体制で、高品質と低コストの両立に成功。 大手の企業様やベンチャー企業様など、幅広いお客様にご活用いただいています。エンジニア不足でお悩みの方は、お気軽にご相談ください。"
      />
      <link rel="canonical" href="https://www.co-well.jp/about" />
      <meta property="og:url" content="https://www.co-well.jp/about" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="https://ferret-one.akamaized.net/images/5f894b21a70eed4f025b74bd/original.png?utime=1602833185"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/navi/style.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/fo-base.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <style
        className="colors-class-list"
        dangerouslySetInnerHTML={{
          __html:
            "\n      .color-31ACC9 {\n        color: #31acc9;\n      }\n      .color-ffffff {\n        color: #ffffff;\n      }\n    ",
        }}
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        rel="stylesheet"
        data-href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        data-optimized-fonts="true"
      />
      <link
        href="/__/api/website/sites/c20081122467/base_design/style"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/global.css"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/sites/c20081122467?utime=1731390832574"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/pages/5f72aecafe1e8e015b35451b?utime=1731390832574"
        rel="stylesheet"
      />
      <meta name="next-head-count" content={28} />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        data-n-p=""
      />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        data-n-p=""
      />
      <noscript data-n-css="" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
      />
      <div id="__next" data-reactroot="">
        <noscript>
          &lt;iframe title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-PXW3TJ3"
          height="0" width="0" style="display: none; visibility: hidden"
          &gt;&lt;/iframe &gt;
        </noscript>
        <div id="page-show" page_title="コウェルについて" form_page="false" />
        <div
          id="page-analyse"
          data-id="5f72aecafe1e8e015b35451b"
          style={{ display: "none" }}
        />
        <Header />
        <div
          className="clearfix theme-fo-base v2-0 no-breadcrumbs"
          id="fo-base"
        >
          <div
            className="main-visual sub-page-custom"
            style={{
              backgroundColor: "#173430",
              backgroundImage:
                "url(https://ferret-one.akamaized.net/images/65dc11e734ac4b0300933acd/original.jpeg?utime=1708921319)",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            <div className="overlay" />
            <div className="clearfix main-visual-content">
              <div className="row row-1">
                <div className="col col-1" style={{ width: "50%" }}>
                  <div
                    id="5f72aecafe1e8e015b35451b-19af6df8912bbe429c1ea978"
                    className="component component-1 rich-heading heading rich-heading-align-center"
                  >
                    <h1>
                      <span className="font-size-32px">コウェルについて</span>
                    </h1>
                  </div>
                </div>
                <div className="col col-2" style={{ width: "50%" }}>
                  <div
                    className="component component-1 text text-align-center"
                    style={{ overflowWrap: "break-word" }}
                  >
                    <span className="font-size-22px">
                      <b>
                        各サービスについての
                        <br />
                        お問い合わせはこちら
                      </b>
                    </span>
                  </div>
                  <div
                    className="component component-2 btn_custom01 button"
                    style={{ padding: "10px 0px 0px 0px" }}
                  >
                    <div className="button-cont center">
                      <Link
                        to="/contact"
                        className="center size-m red"
                        onClick={toTop}
                      >
                        お問い合せ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper clearfix none page-5f72aecafe1e8e015b35451b">
            <div className="contents">
              <section className="content-element content-1">
                <div className="inner">
                  <div className="row row-1 marginBottom50_row">
                    <div className="col col-1">
                      <div
                        id="5f72aecafe1e8e015b35451b-40147e2ba7a97f3d884b01cd"
                        className="component component-1 hd_custom04 rich-heading heading rich-heading-align-center"
                      >
                        <h2>
                          <span className="font-size-30px">
                            オフショア開発における
                            <br />
                            高品質・最適価格でのソフトウェア開発をトータルサポート
                          </span>
                        </h2>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        私たち株式会社コウェルは、2007年の設立当初から、業界内でいち早くオフショア開発をスタート。
                        <br />
                        ベトナムだけでなく日本国内においても、プロフェッショナル・エンジニアによるソフトウェア開発のノウハウを蓄積してまいりました。
                        <br />
                        確かな技術を誇る日本人エンジニアと、一流IT大学出身のベトナム人エンジニアによる開発体制で、高品質と低コストの両立に成功。
                        <br />
                        大手の企業様やベンチャー企業様など、幅広いお客様にご活用いただいています。
                      </div>
                    </div>
                  </div>
                  <div className="row row-2 marginBottom50_row">
                    <div
                      className="col col-1 paddingRight60_col"
                      style={{ width: "50%" }}
                    >
                      <div
                        id="5f72aecafe1e8e015b35451b-58fe79afb100047dc0aae05a"
                        className="component component-1 hd_custom04 rich-heading heading"
                      >
                        <h3>
                          <span className="color-31ACC9">
                            さまざまな開発環境を
                            <br />
                            カバーできるハイレベルな技術力
                          </span>
                        </h3>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        コウェルには、ベトナムの優秀な大学・大学院でソフトウェア開発を学んだスタッフが多数在籍しています。
                        <br />
                        さまざまなOSや開発言語、ツールなどに対応できる豊富な知識で、あらゆる開発環境を幅広くカバー。
                        <br />
                        貴社の抱えるさまざまなソフトウェア開発のご要望を、高品質なオフショア開発で実現します。
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 marginTop0 image resizeable-comp align-center"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/5f7fd4bbb7002072d60b0bab/original.jpeg?utime=1602213051"
                          alt="コウェルについて01"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "100%",
                          }}
                          height={3744}
                          width={5616}
                          role="presentation"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-3 reverse">
                    <div className="col col-1" style={{ width: "50%" }}>
                      <div
                        className="component component-1 marginTop0 image resizeable-comp align-center"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/5f7fd4b6fe514074bff8de9e/original.jpeg?utime=1602213046"
                          alt="コウェルについて02"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "100%",
                          }}
                          height={340}
                          width={643}
                          role="presentation"
                        />
                      </div>
                    </div>
                    <div
                      className="col col-2 paddingLeft60_col"
                      style={{ width: "50%" }}
                    >
                      <div
                        id="5f72aecafe1e8e015b35451b-43547264a242ac9d89f8cfa7"
                        className="component component-1 hd_custom04 rich-heading heading"
                      >
                        <h3>
                          <span className="color-31ACC9">
                            日本語に長けた開発体制で
                            <br />
                            コミュニケーションロスを最小限に
                          </span>
                        </h3>
                      </div>
                      <div
                        className="component component-2 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        オフショア開発では、日々のコミュニケーションを円滑に行うため、日本語の分かるエンジニアの存在が重要です。
                        <br />
                        コウェルのベトナム人エンジニアは、日本語の読解力・会話力はもちろん、日本での留学経験や勤務経験を重視して採用しています。
                        <br />
                        採用後もスタッフに対して日本語研修を積極的に行うことで、安心してソフトウェア開発をお任せいただける開発体制を整えています。
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="content-element content-2 cta_sec"
                style={{
                  backgroundImage:
                    "url(https://ferret-one.akamaized.net/images/65dc18124f670a04b9530a84/original.jpeg?utime=1708922899)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  padding: "0px 0px 0px 0px",
                }}
              >
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        className="component component-1 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <br />
                        <br />
                      </div>
                      <div
                        className="component component-2 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="color-ffffff font-size-30px strong">
                          エンジニア不足でお悩みの企業様は
                          <br />
                          お気軽にご相談ください
                        </em>
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div className="col col-1" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">
                          不明点について
                          <br />
                          相談がしたい
                        </span>
                      </div>
                      <div className="component component-2 btn_custom01 button">
                        <div className="button-cont center">
                          <Link
                            to="/contact"
                            className="center size-m red"
                            onClick={toTop}
                          >
                            無料相談はこちらから
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">
                          コウェルのソリューション
                          <br />
                          がわかる
                        </span>
                      </div>
                      <div className="component component-2 btn_custom02 button">
                        <div className="button-cont center">
                          <Link to="/" className="center size-m black">
                            資料ダウンロード
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-3">
                    <div className="col col-1">
                      <div
                        className="component component-1 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default About;
