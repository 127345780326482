import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="foot ft_custom01" style={{ background: "#fff" }}>
      <div className="foot-cont">
        <div className="row row-1 js_script_row">
          <div className="col col-1">
            <div className="component component-1 script">
              <div style={{ textAlign: "left" }}></div>
            </div>
            <div className="component component-2 script">
              <div style={{ textAlign: "left" }}></div>
            </div>
          </div>
        </div>
        <div className="row row-2 ftLink_row">
          <div className="col col-1" style={{ width: "25%" }}>
            <div
              className="component component-1 foot_logo image resizeable-comp align-left"
              style={{ textAlign: "left" }}
            >
              <Link to="/">
                <img
                  loading="lazy"
                  id=""
                  src="https://ferret-one.akamaized.net/images/634f42e4c174a1397836e8f9/original.png?utime=1666138852"
                  alt=""
                  className=""
                  style={{
                    width: "238px",
                    height: "57px",
                    borderRadius: 0,
                  }}
                />
              </Link>
            </div>
            <div
              className="component component-2 privacy_img image resizeable-comp align-left"
              style={{ textAlign: "left" }}
            >
              <Link to="https://privacymark.jp/" target="_blank" rel="noopener">
                <img
                  loading="lazy"
                  id=""
                  src="https://ferret-one.akamaized.net/images/5f72f5f5fe1e8e1d18357c30/original.png?utime=1601369589"
                  alt="プライバシーマーク"
                  className=""
                  style={{
                    width: "80px",
                    height: "70px",
                    borderRadius: 0,
                  }}
                  role="presentation"
                />
              </Link>
            </div>
          </div>
          <div className="col col-2" style={{ width: "25%" }}>
            <div className="component component-1 list vertical none foot_ul_main">
              <ul style={{ overflowWrap: "break-word" }}>
                <li>
                  <Link to="/">ソリューション</Link>
                </li>
                <li>
                  <Link to="/">選ばれる理由</Link>
                </li>
                <li>
                  <Link to="/">取引事例</Link>
                </li>
                <li>
                  <Link to="/">よくあるご質問/FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-3" style={{ width: "25%" }}>
            <div className="component component-1 list vertical none foot_ul_main">
              <ul style={{ overflowWrap: "break-word" }}>
                <li>
                  <Link to="/">企業情報</Link>
                </li>
                <li>
                  <Link to="/">お知らせ</Link>
                </li>
                <li>
                  <Link to="/">お役立ち情報/BLOG</Link>
                </li>
                <li>
                  <Link to="/">セミナー</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-4" style={{ width: "25%" }}>
            <div className="component component-1 list vertical none foot_ul_main1">
              <ul style={{ overflowWrap: "break-word" }}>
                <li>
                  <Link to="/about" onClick={toTop}>
                    コウェルについて
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" onClick={toTop}>
                    個人情報保護方針
                  </Link>
                </li>
                <li>
                  <Link to="/security" onClick={toTop}>
                    情報セキュリティ基本方針
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toTop}>
                    お問い合わせ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row row-4 sns_row">
          <div
            className="col col-1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              className="component component-1 image resizeable-comp align-center"
              style={{
                textAlign: "center",
                margin: "0 10px;",
                width: "auto",
                display: "inline-block",
              }}
            >
              <Link to="/" target="_blank" rel="noopener">
                <img
                  loading="lazy"
                  id=""
                  src="https://ferret-one.akamaized.net/images/65dc260dfcaf1c018a52e333/original.png?utime=1708926477"
                  alt=""
                  className=""
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: 0,
                  }}
                  role="presentation"
                />
              </Link>
            </div>
            <div
              className="component component-2 image resizeable-comp align-center"
              style={{
                textAlign: "center",
                margin: "0 10px;",
                width: "auto",
                display: "inline-block",
              }}
            >
              <Link to="/" target="_blank" rel="noopener">
                <img
                  loading="lazy"
                  id=""
                  src="https://ferret-one.akamaized.net/images/65dc2509fcaf1c018a52be6e/original.png?utime=1708926217"
                  alt=""
                  className=""
                  style={{
                    width: "40px",
                    height: "41px",
                    borderRadius: 0,
                  }}
                  role="presentation"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="foot-copyright">
        <p>Copyright © 2022 CO-WELL Co., LTD. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
