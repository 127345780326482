import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Footerb from "./Footerb";
import "../css/main.css";
import "../css/mainTwo.css";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content="プロ×オフショアの力で 貴社のDXを成功に導く 伴走型ITパートナー｜コウェル"
      />
      <meta
        property="og:description"
        content="オフショア開発から人材紹介まで、高品質・最適価格でソフトウェア開発・テストをトータルサポートします。株式会社コウェルは、ベトナムでの低コスト開発と日本品質の両立を実現！オフショア開発のお悩みは私たちにご相談ください。"
      />
      <meta
        property="og:site_name"
        content="オフショア開発・テストサービス、ソフトウェア開発までおまかせ｜株式会社コウェル"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="プロ×オフショアの力で 貴社のDXを成功に導く 伴走型ITパートナー｜コウェル"
      />
      <meta
        property="twitter:description"
        content="オフショア開発から人材紹介まで、高品質・最適価格でソフトウェア開発・テストをトータルサポートします。株式会社コウェルは、ベトナムでの低コスト開発と日本品質の両立を実現！オフショア開発のお悩みは私たちにご相談ください。"
      />
      <meta
        name="google-site-verification"
        content="MaaAAid_F2i_ZSvor501oIuxGtSMN5Bhx-fxILiiCgM"
      />
      <meta
        property="og:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <meta
        property="twitter:image"
        content="https://ferret-one.akamaized.net/images/5f864ced50fa3d038b06eff6/large.png?utime=1602637037"
      />
      <title>
        プロ×オフショアの力で 貴社のDXを成功に導く 伴走型ITパートナー｜コウェル
      </title>
      <meta
        name="description"
        content="オフショア開発から人材紹介まで、高品質・最適価格でソフトウェア開発・テストをトータルサポートします。株式会社コウェルは、ベトナムでの低コスト開発と日本品質の両立を実現！オフショア開発のお悩みは私たちにご相談ください。"
      />
      <link rel="canonical" href="https://www.co-well.jp" />
      <meta property="og:url" content="https://www.co-well.jp" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="https://ferret-one.akamaized.net/images/5f894b21a70eed4f025b74bd/original.png?utime=1602833185"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/navi/style.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/theme/cms/fo-base.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <style
        className="colors-class-list"
        dangerouslySetInnerHTML={{
          __html:
            "\n      .color-ffffff {\n        color: #ffffff;\n      }\n      .color-2d2d2d {\n        color: #2d2d2d;\n      }\n      .color-173430 {\n        color: #173430;\n      }\n    ",
        }}
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        rel="stylesheet"
        data-href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        data-optimized-fonts="true"
      />
      <link
        href="/__/api/website/sites/c20081122467/base_design/style"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/global.css"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/sites/c20081122467?utime=1731390824769"
        rel="stylesheet"
      />
      <link
        href="/__/custom_css/pages/5f7288e8389f221d646b0ad4?utime=1731390824769"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/section-empty.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/section-about.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/row-sub-text-v2.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/row-feature.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/rich-text-normal.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/button-type1-arrow.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/rich-heading-normal.css"
        rel="stylesheet"
      />
      <link
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/css/designparts/website/button-white-arrow.css"
        rel="stylesheet"
      />
      <meta name="next-head-count" content={37} />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/6ffbdf050ff17e15.css"
        data-n-p=""
      />
      <link
        rel="preload"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://ferret-one.akamaized.net/cms/website/build_20241112140634/_next/static/css/8ec5f06b9efe4853.css"
        data-n-p=""
      />
      <noscript data-n-css="" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
      />
      <div id="__next" data-reactroot="">
        <noscript>
          &lt;iframe title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-PXW3TJ3"
          height="0" width="0" style="display: none; visibility: hidden"
          &gt;&lt;/iframe &gt;
        </noscript>
        <div id="page-show" page_title="TOP" form_page="false" />
        <div
          id="page-analyse"
          data-id="5f7288e8389f221d646b0ad4"
          style={{ display: "none" }}
        />
        <Header />
        <div
          className="clearfix theme-fo-base v2-0 no-breadcrumbs"
          id="fo-base"
        >
          <div
            className="main-visual"
            style={{
              backgroundImage:
                "url(https://ferret-one.akamaized.net/images/65dd4d531f61c4099f84856a/original.jpeg?utime=1709002067)",
              backgroundSize: "cover",
              backgroundPosition: "top left",
              padding: "0px 0px 0px 0px",
              minHeight: 500,
            }}
          >
            <div className="overlay" style={{ opacity: 0 }} />
            <div className="clearfix main-visual-content">
              <div className="row row-1">
                <div className="col col-1" style={{ width: "50%" }}>
                  <div
                    id="5f7288e8389f221d646b0ad4-31b0f407ff76156b217a56ef"
                    className="component component-1 rich-heading heading rich-heading-align-center"
                    style={{ padding: "60px 0px 0px 0px" }}
                  >
                    <h1>
                      <span className="font-size-32px">
                        ベトナム × 日本
                        <br />
                        高品質・最適価格で
                        <br />
                        ソフトウェア開発をトータルサポート
                      </span>
                      <span className="color-ffffff font-size-42px">
                        <br />
                      </span>
                    </h1>
                  </div>
                  <div
                    className="component component-2 btn_custom01 button"
                    style={{ padding: "20px 0px 0px 0px" }}
                  >
                    <div className="button-cont center">
                      <Link to="/contact" className="center size-m red">
                        今すぐ相談
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col col-2" style={{ width: "50%" }}>
                  <div
                    className="component component-1 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dd4d5c1f61c408b584d6bf/original.png?utime=1709002076"
                      alt=""
                      className=""
                      style={{ width: 526, borderRadius: 0, maxWidth: "100%" }}
                      height={970}
                      width={1080}
                      role="presentation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper clearfix none page-5f7288e8389f221d646b0ad4">
            <div className="contents">
              <section
                className="content-element content-1 logoScroll_sec js-logoScroll_sec"
                style={{ padding: "0px 0px 0px 0px" }}
              >
                <div
                  className="image-row"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="component component-1 image resizeable-comp align-left">
                    <a href="/blog/case_bellface">
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36fad6a6870512d59b79/original.png?utime=1708930810"
                        alt="bellface"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "150%",
                          margin: "10px", // 根据需要调整边距
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                      />
                    </a>
                  </div>
                  <div className="component component-2 image">
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/666a78ae91e8f907a6ad1dbc/original.png?utime=1718253743"
                      alt="寺田倉庫"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={315}
                      width={600}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-3 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f8d6a6870512d59b75/original.png?utime=1708930808"
                      alt="GDO"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-4 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f9fcaf1c04f952d6ed/original.png?utime=1708930809"
                      alt="idom"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-5 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36fb343b00046768542c/original.png?utime=1708930811"
                      alt="amana"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-6 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f4d6a6870512d59b73/original.png?utime=1708930804"
                      alt="宇佐美"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-7 image resizeable-comp align-right"
                    style={{ textAlign: "right" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f5fcaf1c048c5301ce/original.png?utime=1708930805"
                      alt="バンドー化学"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>

                  <div
                    className="component component-10 image resizeable-comp align-center"
                    style={{ textAlign: "center", margin: "10px", flexGrow: 1 }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65effee18925d00dbdb63b57/original.jpeg?utime=1710227169"
                      alt=""
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-11 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc4655343b0007a6681028/original.png?utime=1708934741"
                      alt="JAST"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-12 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36fcfcaf1c048c5301d4/original.png?utime=1708930812"
                      alt="AEON"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-13 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f6d6a687053cd5660f/original.png?utime=1708930806"
                      alt="TSUTAYA"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-14 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f5343b00043568ffce/original.png?utime=1708930805"
                      alt="willgroup"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-15 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f7343b0004676853ef/original.png?utime=1708930807"
                      alt="TOKAIコミニュケーションズ"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-16 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f4343b0004676853ed/original.png?utime=1708938867"
                      alt="明電システムソリューション"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-17 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36f7fcaf1c048c5301d0/original.png?utime=1708930807"
                      alt="saint-works"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                  <div
                    className="component component-18 image resizeable-comp align-left"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      loading="lazy"
                      id=""
                      src="https://ferret-one.akamaized.net/images/65dc36fbd6a6870512d59bdb/original.png?utime=1708930811"
                      alt="Allied Architects"
                      className=""
                      style={{
                        width: "auto",
                        borderRadius: 0,
                        maxWidth: "100%",
                        margin: "10px", // 根据需要调整边距
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                      }}
                      height={176}
                      width={436}
                      role="presentation"
                    />
                  </div>
                </div>
              </section>
              <section
                className="content-element content-2"
                data-designpart="section-empty"
                data-designpart-id="642f669ce4dc09621d945b2d"
                style={{ padding: "0px 0px 0px 0px" }}
              >
                <div className="inner">
                  <div
                    className="row row-1"
                    style={{ padding: "0px 0px 0px 0px" }}
                  >
                    <div
                      className="col col-1"
                      style={{ padding: "0px 0px 0px 0px", width: "50%" }}
                    >
                      <div
                        className="component component-1 richtext"
                        style={{ padding: "0px 0px 0px 0px" }}
                        data-designpart="rich-text-normal"
                        data-designpart-id="642f669ce4dc09621d945ade"
                      >
                        <p className="align-center">
                          <span style={{ fontSize: 20 }}>
                            <strong>各種資料をご覧いただけます</strong>
                          </span>
                        </p>
                      </div>
                      <div
                        className="component component-2 button"
                        style={{ padding: "0px 0px 0px 0px" }}
                        data-designpart="button-type1-arrow"
                        data-designpart-id="642f669ce4dc09621d945abf"
                      >
                        <div className="button-cont center">
                          <a href="/download" className="center size-m blue">
                            DL資料一覧をみる
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 richtext"
                        data-designpart="rich-text-normal"
                        data-designpart-id="642f669ce4dc09621d945ade"
                      >
                        <p className="align-center">
                          <span style={{ fontSize: 20 }}>
                            <strong>案件・開発のご相談はお気軽にどうぞ</strong>
                          </span>
                        </p>
                      </div>
                      <div
                        className="component component-2 button"
                        style={{ padding: "0px 0px 0px 0px" }}
                        data-designpart="button-type1-arrow"
                        data-designpart-id="642f669ce4dc09621d945abf"
                      >
                        <div className="button-cont center">
                          <a href="/contact" className="center size-m blue">
                            無料相談をする
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="content-element content-3"
                data-designpart="section-about"
                data-designpart-id="642f669ce4dc09621d945b10"
                style={{
                  backgroundImage:
                    "url(https://ferret-one.akamaized.net/images/6704bd6810ecd2017e3869b0/original.jpeg?utime=1728363880)",
                }}
              >
                <div className="inner">
                  <div
                    className="row row-1"
                    data-designpart="row-sub-text-v2"
                    data-designpart-id="660bafe4625e12cfe8690ec1"
                  >
                    <div className="col col-1" style={{ width: "100%" }}>
                      <div
                        id="5f7288e8389f221d646b0ad4-bc418caa21bd913b0fa5ab6d"
                        className="component component-1 rich-heading heading rich-heading-align-center"
                        data-designpart="rich-heading-normal"
                        data-designpart-id="642f669ce4dc09621d945adb"
                      >
                        <h2>提供ソリューション一覧</h2>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row row-2"
                    data-designpart="row-feature"
                    data-designpart-id="642f669ce4dc09621d945aed"
                  >
                    <div className="col col-1" style={{ width: "25%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <a href="/service/offshore">
                          <img
                            loading="lazy"
                            id=""
                            src="https://ferret-one.akamaized.net/images/66fe2ff0490f9d02916b016c/original.png?utime=1727934448"
                            alt="labアイコン"
                            className=""
                            style={{
                              width: 120,
                              borderRadius: 0,
                              maxWidth: "100%",
                            }}
                            height={500}
                            width={500}
                            role="presentation"
                          />
                        </a>
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-73f24ea0678598eec488b2a9"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                        data-designpart="rich-heading-normal"
                        data-designpart-id="642f669ce4dc09621d945adb"
                      >
                        <h3>
                          <span className="color-2d2d2d font-size-24px">
                            ラボ型開発
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "25%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <a href="/service/ec">
                          <img
                            loading="lazy"
                            id=""
                            src="https://ferret-one.akamaized.net/images/66fe3009eacaec02dbaf9ffc/original.png?utime=1727934473"
                            alt="ecアイコン"
                            className=""
                            style={{
                              width: 120,
                              borderRadius: 0,
                              maxWidth: "100%",
                            }}
                            height={500}
                            width={500}
                            role="presentation"
                          />
                        </a>
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-f558e09f23c68f5ddfe796e6"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                        data-designpart="rich-heading-normal"
                        data-designpart-id="642f669ce4dc09621d945adb"
                      >
                        <h3>
                          <span className="color-2d2d2d font-size-24px">
                            EC構築サービス
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="col col-3" style={{ width: "25%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <a href="/service/software-test">
                          <img
                            loading="lazy"
                            id=""
                            src="https://ferret-one.akamaized.net/images/66fe301f490f9d02cc6b017a/original.png?utime=1727934495"
                            alt="テストサービスアイコン"
                            className=""
                            style={{
                              width: 120,
                              borderRadius: 0,
                              maxWidth: "100%",
                            }}
                            height={500}
                            width={500}
                            role="presentation"
                          />
                        </a>
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-d479f36fc0be22b029dde480"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                        data-designpart="rich-heading-normal"
                        data-designpart-id="642f669ce4dc09621d945adb"
                      >
                        <h3>
                          <span className="color-2d2d2d font-size-24px">
                            ソフトウェアテスト
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="col col-4" style={{ width: "25%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <a href="/service/cloudintegration">
                          <img
                            loading="lazy"
                            id=""
                            src="https://ferret-one.akamaized.net/images/66fe3045eacaec02dbafa005/original.png?utime=1727934533"
                            alt="クラウドアイコン"
                            className=""
                            style={{
                              width: 120,
                              borderRadius: 0,
                              maxWidth: "100%",
                            }}
                            height={500}
                            width={500}
                            role="presentation"
                          />
                        </a>
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-4ab1b8745e07fc3523c39030"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                        data-designpart="rich-heading-normal"
                        data-designpart-id="642f669ce4dc09621d945adb"
                      >
                        <h3>
                          <span className="color-2d2d2d font-size-24px">
                            クラウド
                            <br />
                            インテグレーション
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row row-3">
                    <div className="col col-1">
                      <div
                        className="component component-1 richtext"
                        data-designpart="rich-text-normal"
                        data-designpart-id="642f669ce4dc09621d945ade"
                      >
                        <p className="align-center">
                          <span style={{ color: "#ffffff" }}>
                            オフショア開発における高品質・最適価格でのソフトウェア開発をトータルサポートします。ラボ型開発をはじめ、大規模・越境EC構築サービスやプロ集団によるソフトウェアテストサービス、AWSパートナー様向けのクラウドインテグレーションまで包括的にご提供します。
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row row-4">
                    <div className="col col-1">
                      <div
                        className="component component-1 button"
                        data-designpart="button-white-arrow"
                        data-designpart-id="642f669ce4dc09621d945acb"
                      >
                        <div className="button-cont center">
                          <a
                            href="/service"
                            className="center size-m blue"
                            style={{ fontWeight: "bold" }}
                          >
                            ソリューションについて
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-4">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288e8389f221d646b0ad4-e18bd2eeefa6f0f97797e112"
                        className="component component-1 rich-heading heading rich-heading-align-center"
                      >
                        <h2>
                          <span className="font-size-32px">
                            オフショア開発のこんな課題に
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row row-4 style01_row">
                    <div className="col col-1" style={{ width: "50%" }}>
                      <div
                        className="component component-1 borderBox_text text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        高品質と低コストを両立したい
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 borderBox_text text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        自社の開発部署を拡大したい
                      </div>
                    </div>
                  </div>
                  <div className="row row-5 style01_row">
                    <div className="col col-1">
                      <div
                        className="component component-1 borderBox_text text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        自社エンジニアを新規開発に集中させ保守・運用をアウトソースしたい
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-5 topTask_sec">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="color-173430 font-size-20px strong">
                          <em className="new font-size-32px strong">
                            ソフトウェア開発から保守まで、
                            <br />
                            貴社の開発業務を安心してコウェルにお任せください。
                          </em>
                          <br />
                        </em>
                      </div>
                    </div>
                  </div>
                  <div className="row row-2 topTask_row">
                    <div
                      className="col col-1 textL_col"
                      style={{ width: "50%" }}
                    >
                      <div
                        id="5f7288e8389f221d646b0ad4-e3935a392f8ea91ed2558144"
                        className="component component-1 hd_custom02 rich-heading heading"
                      >
                        <h3>
                          優秀なベトナム人エンジニアの
                          <div className="typesquare_tags">
                            <span className="font-size-32px">採用力</span>
                            <span className="font-size-default">と</span>
                            <span className="font-size-32px">徹底した</span>
                            <span className="font-size-32px">人材育成</span>
                          </div>
                        </h3>
                      </div>
                      <div
                        className="component component-2 number1_custom text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        私たちは、ハノイ工科大学をはじめとするベトナムのIT分野トップ3の大学と連携。
                        <br />
                        卒業生の半数以上が弊社へ応募し、そのなかでも特に優秀な人材を選抜して採用しています。
                        <br />
                        また、全社員が日本語で業務を行えるよう、専任の日本語講師によるレッスンでコミュニケーション能力を育んでいます。
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/5f73ea18ff8c757087e532fb/original.png?utime=1601432088"
                          alt="課題01"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "150%",
                            margin: "10px", // 根据需要调整边距
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                          }}
                          height={746}
                          width={1440}
                          role="presentation"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-3 topTask_row reverse">
                    <div className="col col-1 img_col" style={{ width: "50%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/5f73ea18ff8c75736be4c213/original.png?utime=1601432088"
                          alt="課題02"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "150%",
                            margin: "10px", // 根据需要调整边距
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                          }}
                          height={746}
                          width={1440}
                          role="presentation"
                        />
                      </div>
                    </div>
                    <div
                      className="col col-2 textR_col"
                      style={{ width: "50%" }}
                    >
                      <div
                        id="5f7288e8389f221d646b0ad4-fe038879135bd1aa9685ee6d"
                        className="component component-1 hd_custom02 rich-heading heading"
                      >
                        <h3>
                          日本人スタッフによる
                          <span className="font-size-32px typesquare_tags">
                            手厚いサポート
                          </span>
                          で<br />
                          オフショア開発に
                          <span className="font-size-32px typesquare_tags">
                            安心感
                          </span>
                          をプラス
                          <div className="typesquare_tags" />
                        </h3>
                      </div>
                      <div
                        className="component component-2 number2_custom right text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        ベトナムのオフショア開発拠点だけでなく、日本国内にも優秀な日本人エンジニアが多数在籍しています。
                        <br />
                        要件定義といった開発支援のほか、ベトナム現地でのオペレーションにより、コミュニケーションでの障壁を解消。
                        <br />
                        安心かつ効果的にオフショア開発を進行できます。
                      </div>
                    </div>
                  </div>
                  <div className="row row-4 topTask_row">
                    <div
                      className="col col-1 textL_col"
                      style={{ width: "50%" }}
                    >
                      <div
                        id="5f7288e8389f221d646b0ad4-e1e9880f28004ba67866e3ff"
                        className="component component-1 hd_custom02 rich-heading heading"
                      >
                        <h3>
                          最上位資格の認定が裏付ける
                          <br />
                          <span className="font-size-32px typesquare_tags">
                            グローバルレベルでの高い品質
                          </span>
                          <div className="typesquare_tags" />
                        </h3>
                      </div>
                      <div
                        className="component component-2 number3_custom text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        私たち株式会社コウェルは、ソフトウェアテストに関する国際的な資格認定機関「ISTQB」のパートナーシッププログラムに参加。全世界で9社、日本で3社のみが認定されている最上位資格「Global
                        Partner」を取得いたしました。
                        <br />
                      </div>
                      <div
                        className="component component-3 richtext"
                        style={{ padding: "0px 0px 0px 0px" }}
                      >
                        <p>
                          ※ISTQBについては
                          <a
                            href="//www.co-well.jp/blog/softwaretest_jstqb"
                            target=""
                          >
                            こちら
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/5f9a184942f2561428b8a398/original.png?utime=1603934281"
                          alt="課題03"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "150%",
                            margin: "10px", // 根据需要调整边距
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                          }}
                          height={746}
                          width={1440}
                          role="presentation"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-5 defaultWidth_row">
                    <div className="col col-1 sp_paddingTop10_col">
                      <div className="component component-1 btn_custom03 button">
                        <div className="button-cont right">
                          <a href="/reason" className="right size-s blue">
                            選ばれる理由
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="content-element content-6"
                style={{
                  backgroundImage:
                    "url(https://ferret-one.akamaized.net/images/65d98212e963b31d79c9de0f/original.jpeg?utime=1708753426)",
                  padding: "0px 0px 0px 0px",
                }}
              >
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">
                          お客様の課題解決に最適なプランをご提案いたします
                          <br />
                        </span>
                        <b className="color-ffffff">
                          <span className="font-size-26px">
                            まずは貴社の課題をお聞かせください
                          </span>
                        </b>
                        <b />
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div className="col col-1" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">些細なご質問でもOK</span>
                      </div>
                      <div className="component component-2 btn_custom01 button">
                        <div className="button-cont center">
                          <Link
                            to="/contact"
                            className="center size-m red"
                            onClick={toTop}
                          >
                            無料相談はこちらから
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">
                          サービス資料はこちら
                        </span>
                      </div>
                      <div className="component component-2 btn_custom02 button">
                        <div className="button-cont center">
                          <Link to="/" className="center size-m black">
                            資料ダウンロード
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-7">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288e8389f221d646b0ad4-2756dc8c846480554527ef16"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h2>
                          課題別ソリューション
                          <br />
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div
                      className="col col-1"
                      style={{ width: "33.333333333333336%" }}
                    >
                      <div className="component component-1 image">
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/65dd313eb96d1302f483b1f1/large.png?utime=1708994878"
                          alt=""
                          className=""
                          style={{
                            width: "100%",
                            borderRadius: 0,
                            maxWidth: "100%",
                          }}
                          height={480}
                          width={640}
                          role="presentation"
                        />
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-8417c2d9c4ba1c27b5e9ee3b"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                      >
                        <h3>
                          <span className="font-size-28px">ラボ型開発</span>
                        </h3>
                      </div>
                      <div
                        className="component component-3 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        日本品質 × 最適価格を両立した
                        <br />
                        オフショア開発体制を実現
                      </div>
                      <div className="component component-4 btn_custom04 button">
                        <div className="button-cont center">
                          <a
                            href="/service/offshore"
                            className="center size-s red"
                            style={{ fontSize: "default", borderRadius: 20 }}
                          >
                            詳しく見る
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col col-2"
                      style={{ width: "33.333333333333336%" }}
                    >
                      <div className="component component-1 image">
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/65dd3145b96d1302bd83c795/large.png?utime=1708994885"
                          alt=""
                          className=""
                          style={{
                            width: "100%",
                            borderRadius: 0,
                            maxWidth: "100%",
                          }}
                          height={480}
                          width={640}
                          role="presentation"
                        />
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-24d808643125b5b6267f5416"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                      >
                        <h3>
                          <span className="font-size-28px">
                            ソフトウェアテスト
                          </span>
                          <br />
                        </h3>
                      </div>
                      <div
                        className="component component-3 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        最上位資格の認定を受けた
                        <br />
                        高品質なソフトウェアテスト体制
                      </div>
                      <div className="component component-4 btn_custom04 button">
                        <div className="button-cont center">
                          <a
                            href="/service/software-test"
                            className="center size-s red"
                            style={{ borderRadius: 20 }}
                          >
                            詳しく見る
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col col-3"
                      style={{ width: "33.333333333333336%" }}
                    >
                      <div className="component component-1 image">
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/65dd3137ab69fd034d488354/large.png?utime=1708994871"
                          alt=""
                          className=""
                          style={{
                            width: "100%",
                            borderRadius: 0,
                            maxWidth: "100%",
                          }}
                          height={480}
                          width={640}
                          role="presentation"
                        />
                      </div>
                      <div
                        id="5f7288e8389f221d646b0ad4-875f0637e069e8f29ef5f878"
                        className="component component-2 rich-heading heading rich-heading-align-center"
                      >
                        <h3>
                          <span className="font-size-28px">EC構築サービス</span>
                          <br />
                        </h3>
                      </div>
                      <div
                        className="component component-3 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        Eコマース開発経験豊富なエンジニアがワンストップでEコマース開発を支援
                      </div>
                      <div className="component component-4 btn_custom04 button">
                        <div className="button-cont center">
                          <a
                            href="/service/ec"
                            className="center size-s red"
                            style={{ borderRadius: 20 }}
                          >
                            詳しく見る
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-8">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288e8389f221d646b0ad4-15c53725ce8da4dfd48cdb4a"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h2>関連記事</h2>
                      </div>
                      <div className="component component-2 caseContent_style entry-list entry-image-card-3-col">
                        <div className="article-list">
                          <div className="entry-list-unit">
                            <a
                              className="img"
                              href="/blog/softwaretest_jstqb_v4.0"
                            >
                              <img
                                loading="lazy"
                                id=""
                                src="https://ferret-one.akamaized.net/images/666aa2e4559d71110d81cfdf/large.jpeg?utime=1718264548"
                                alt=""
                                className=""
                                height={1000}
                                width={1500}
                                role="presentation"
                                data-retry={0}
                              />
                            </a>
                            <div className="cont">
                              <h3>
                                <a href="/blog/softwaretest_jstqb_v4.0">
                                  2024年速報）JSTQBが「Certified Tester
                                  Foundation Level (CTFL)
                                  v4.0」の日本語翻訳版をリリース。以前との違いや注目点は？
                                </a>
                              </h3>
                              <p
                                className="category"
                                style={{ color: "rgb(67, 92, 113)" }}
                              >
                                <i
                                  className="fa fa-bars"
                                  style={{ marginRight: 5 }}
                                />
                                <a href="/__/categories/solution">
                                  ソリューション
                                </a>
                              </p>
                              <h4>
                                2024-06-13 16:01
                                <span className="update-time">
                                  更新： 2024-06-14 11:02
                                </span>
                              </h4>
                              <p className="description">
                                最新のJSTQB CTFL v4.0について
                                2024年の最新情報として、ソフトウェアテストの専門家の皆さんに朗報です。
                                ISTQBの日本における加盟組織であるJSTQBは、2023年9月にソフトウェアテストの基礎的な知識を認定する新たなスタンダードとしてCTFLのバージョン4.0である「Certified
                                Tester Foundation Level (CTFL)
                                v4.0」の日本語翻訳版をリリースしました。
                                このバージョンアップデートにより、ソフトウェアテストの基礎知識をさらに深めることができ、最新版シラバスは、従来の内容を大幅にアップデートし、最新のアジャイル開発手法を取り入れた包括的な内容となっています。
                                本記事ではFoundationLevelの資格取得を目指す方々に向けて、3.1からの変更点やCTFL
                                v4.0の主な注目すべきポイントについて詳しく解説します。
                              </p>
                              <div className="tags">
                                <a
                                  className="tag"
                                  href="/__/tags/%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%83%86%E3%82%B9%E3%83%88"
                                >
                                  <i className="fa fa-tag" />
                                  ソフトウェアテスト
                                </a>
                                <a className="tag" href="/__/tags/JSTQB">
                                  <i className="fa fa-tag" />
                                  JSTQB
                                </a>
                                <a className="tag" href="/__/tags/ISTQB">
                                  <i className="fa fa-tag" />
                                  ISTQB
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="entry-list-unit">
                            <a className="img" href="/blog/ec_adobe_shopify">
                              <img
                                loading="lazy"
                                id=""
                                src="https://ferret-one.akamaized.net/images/661e0a87fac5120204edd767/large.jpeg?utime=1713244807"
                                alt=""
                                className=""
                                height={1000}
                                width={1500}
                                role="presentation"
                                data-retry={0}
                              />
                            </a>
                            <div className="cont">
                              <h3>
                                <a href="/blog/ec_adobe_shopify">
                                  ShopifyとAdobe
                                  Commerce徹底比較！自社に最適なECプラットフォームの選び方
                                </a>
                              </h3>
                              <p
                                className="category"
                                style={{ color: "rgb(67, 92, 113)" }}
                              >
                                <i
                                  className="fa fa-bars"
                                  style={{ marginRight: 5 }}
                                />
                                <a href="/__/categories/solution">
                                  ソリューション
                                </a>
                              </p>
                              <h4>
                                2024-04-17 13:49
                                <span className="update-time">
                                  更新： 2024-06-13 10:31
                                </span>
                              </h4>
                              <p className="description">
                                ShopifyとAdobeCommerceの詳細な比較を通じて、中小企業が直面するECプラットフォーム選定の課題を解説。両プラットフォームの基本情報、機能比較、ユーザーレビューを分析し、ビジネス戦略に適した情報を案内。
                              </p>
                              <div className="tags">
                                <a
                                  className="tag"
                                  href="/__/tags/EC%E6%A7%8B%E7%AF%89"
                                >
                                  <i className="fa fa-tag" />
                                  EC構築
                                </a>
                                <a
                                  className="tag"
                                  href="/__/tags/Adobe%20Commerce"
                                >
                                  <i className="fa fa-tag" />
                                  Adobe Commerce
                                </a>
                                <a className="tag" href="/__/tags/Shopify">
                                  <i className="fa fa-tag" />
                                  Shopify
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="entry-list-unit">
                            <a className="img" href="/blog/softwaretest_jstqb">
                              <img
                                loading="lazy"
                                id=""
                                src="https://ferret-one.akamaized.net/images/62d78972a290610436ac890e/large.jpeg?utime=1658292594"
                                alt=""
                                className=""
                                height={1000}
                                width={1500}
                                role="presentation"
                                data-retry={0}
                              />
                            </a>
                            <div className="cont">
                              <h3>
                                <a href="/blog/softwaretest_jstqb">
                                  2024年最新）ソフトウェアテスト資格「JSTQB認定」「ISTQB認定」テスト技術者資格とは？違いや難易度は？
                                </a>
                              </h3>
                              <p
                                className="category"
                                style={{ color: "rgb(67, 92, 113)" }}
                              >
                                <i
                                  className="fa fa-bars"
                                  style={{ marginRight: 5 }}
                                />
                                <a href="/__/categories/solution">
                                  ソリューション
                                </a>
                              </p>
                              <h4>
                                2024-01-18 17:00
                                <span className="update-time">
                                  更新： 2024-01-18 17:06
                                </span>
                              </h4>
                              <p className="description">
                                2024年最新）ソフトウェアテスト技術者資格として有名なJSTQB認定テスト技術者資格。ソフトウェアテスト・第三者検証で注目されるJSTQB認定テスト技術者資格とは、どのような資格試験なのでしょうか？STQB認定テスト技術者資格の基本情報やISTQB認定テスト技術者資格との違い、開発現場で知っておきたいポイントをまとめました。
                              </p>
                              <div className="tags">
                                <a
                                  className="tag"
                                  href="/__/tags/%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%83%86%E3%82%B9%E3%83%88"
                                >
                                  <i className="fa fa-tag" />
                                  ソフトウェアテスト
                                </a>
                                <a className="tag" href="/__/tags/JSTQB">
                                  <i className="fa fa-tag" />
                                  JSTQB
                                </a>
                                <a className="tag" href="/__/tags/ISTQB">
                                  <i className="fa fa-tag" />
                                  ISTQB
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="article-per-page" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content-element content-9">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1" style={{ width: "100%" }}>
                      <div
                        id="5f7288e8389f221d646b0ad4-2a18ae33cd30dbf8a489c948"
                        className="component component-1 hd_custom01 rich-heading heading rich-heading-align-left"
                      >
                        <h2>取引先企業一例</h2>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col col-1"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="component component-1 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <a href="/blog/case_bellface">
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/65dc36fad6a6870512d59b79/original.png?utime=1708930810"
                          alt="bellface"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "20%",
                          }}
                          height={176}
                          width={436}
                          role="presentation"
                          data-retry={0}
                        />
                      </a>
                    </div>
                    <div
                      className="component component-2 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/666a78ae91e8f907a6ad1dbc/original.png?utime=1718253743"
                        alt="寺田倉庫ロゴ"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={315}
                        width={600}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-3 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f8d6a6870512d59b75/original.png?utime=1708930808"
                        alt="GDO"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-4 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f9fcaf1c04f952d6ed/original.png?utime=1708930809"
                        alt="idom"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-5 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36fb343b00046768542c/original.png?utime=1708930811"
                        alt="amana"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-6 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f4d6a6870512d59b73/original.png?utime=1708930804"
                        alt="宇佐美"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-7 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f5fcaf1c048c5301ce/original.png?utime=1708930805"
                        alt="バンドー化学"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-11 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc4655343b0007a6681028/original.png?utime=1708934741"
                        alt="JAST"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-12 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36fcfcaf1c048c5301d4/original.png?utime=1708930812"
                        alt="AEON"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-13 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f6d6a687053cd5660f/original.png?utime=1708930806"
                        alt="TSUTAYA"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-14 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f5343b00043568ffce/original.png?utime=1708930805"
                        alt="willgroup"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-15 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f7343b0004676853ef/original.png?utime=1708930807"
                        alt="TOKAIコミニュケーションズ"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-16 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f4343b0004676853ed/original.png?utime=1708938867"
                        alt="明電システムソリューション"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-17 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36f7fcaf1c048c5301d0/original.png?utime=1708930807"
                        alt="saint-works"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                    <div
                      className="component component-18 image resizeable-comp align-center"
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <img
                        loading="lazy"
                        id=""
                        src="https://ferret-one.akamaized.net/images/65dc36fbd6a6870512d59bdb/original.png?utime=1708930811"
                        alt="Allied Architects"
                        className=""
                        style={{
                          width: "auto",
                          borderRadius: 0,
                          maxWidth: "20%",
                        }}
                        height={176}
                        width={436}
                        role="presentation"
                        data-retry={0}
                      />
                    </div>
                  </div>

                  <div className="row row-4 topBtn_row">
                    <div className="col col-1">
                      <div className="component component-1 btn_custom03 button">
                        <div className="button-cont right">
                          <a href="/works" className="right size-s blue">
                            取引事例を見る
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content-element content-10">
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        id="5f7288e8389f221d646b0ad4-b464bf94141e6d9fd8eab3da"
                        className="component component-1 hd_custom01 rich-heading heading"
                      >
                        <h2>お知らせ</h2>
                      </div>
                      <div className="component component-2 news_list entry-list">
                        <div className="article-list">
                          <div className="entry-list-unit">
                            <div className="cont">
                              <h3>
                                <a href="/news/20241101">
                                  執行役員人事に関するお知らせ
                                </a>
                              </h3>
                              <h4>2024-11-01</h4>
                            </div>
                          </div>
                          <div className="entry-list-unit">
                            <div className="cont">
                              <h3>
                                <a href="/news/20240902">
                                  【Webセミナー】10月01日開催
                                  成功事例から学ぶプロセス改善の秘訣：ツール導入で変わるテストの未来
                                </a>
                              </h3>
                              <h4>2024-09-02</h4>
                            </div>
                          </div>
                          <div className="entry-list-unit">
                            <div className="cont">
                              <h3>
                                <a href="/news/20240819">
                                  【＠東銀座セミナー】9月3 日開催
                                  自動テスト導入の秘訣
                                  安定稼働の鍵を握る最適なアプローチ
                                </a>
                              </h3>
                              <h4>2024-08-19</h4>
                            </div>
                          </div>
                          <div className="entry-list-unit">
                            <div className="cont">
                              <h3>
                                <a href="/news/20240801">
                                  当社ベトナム子会社の代表者の異動(社長交代)について
                                </a>
                              </h3>
                              <h4>2024-08-01</h4>
                            </div>
                          </div>
                          <div className="entry-list-unit">
                            <div className="cont">
                              <h3>
                                <a href="/news/20240415">
                                  AWSパートナー向けにベトナムオフショアでのクラウドインテグレーションサービス開始
                                </a>
                              </h3>
                              <h4>2024-04-15</h4>
                            </div>
                          </div>
                          <div className="article-per-page" />
                        </div>
                      </div>

                      <div className="component component-3 btn_custom03 button">
                        <div className="button-cont right">
                          <a href="/news" className="right size-s blue">
                            お知らせ一覧
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="content-element content-11 cta_sec"
                style={{
                  backgroundImage:
                    "url(https://ferret-one.akamaized.net/images/65dc18124f670a04b9530a84/original.jpeg?utime=1708922899)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="inner">
                  <div className="row row-1">
                    <div className="col col-1">
                      <div
                        className="component component-1 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <br />
                        <br />
                      </div>
                      <div
                        className="component component-2 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <em className="color-ffffff font-size-30px strong">
                          エンジニア不足でお悩みの企業様は
                          <br />
                          お気軽にご相談ください
                        </em>
                      </div>
                    </div>
                  </div>
                  <div className="row row-2">
                    <div className="col col-1" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">
                          不明点について
                          <br />
                          相談がしたい
                        </span>
                      </div>
                      <div className="component component-2 btn_custom01 button">
                        <div className="button-cont center">
                          <Link
                            to="/contact"
                            className="center size-m red"
                            onClick={toTop}
                          >
                            無料相談はこちらから
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col col-2" style={{ width: "50%" }}>
                      <div
                        className="component component-1 text text-align-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <span className="color-ffffff">
                          コウェルのソリューション
                          <br />
                          がわかる
                        </span>
                      </div>
                      <div className="component component-2 btn_custom02 button">
                        <div className="button-cont center">
                          <Link to="/" className="center size-m black">
                            資料ダウンロード
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-3">
                    <div className="col col-1">
                      <div
                        className="component component-1 text text-align-left"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="row row-4 js-banner_row banner_row">
                    <div className="col col-1">
                      <div
                        className="component component-1 banner_img image resizeable-comp align-center"
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <a
                          href="/download/lab"
                          data-hu-event="TOP_JS_POPUP"
                          data-hu-actiontype="click"
                        >
                          <img
                            loading="lazy"
                            id=""
                            src="https://ferret-one.akamaized.net/images/6719f53f51550002ceb04520/original.png?utime=1729754431"
                            alt="開発サービス資料DLサムネ"
                            className=""
                            style={{
                              width: "auto",
                              borderRadius: 0,
                              maxWidth: "100%",
                            }}
                            height={300}
                            width={300}
                            role="presentation"
                          />
                        </a>
                      </div>
                      <div
                        className="component component-2 js-banner_close_img banner_close_img image resizeable-comp align-right"
                        style={{ textAlign: "right" }}
                      >
                        <img
                          loading="lazy"
                          id=""
                          src="https://ferret-one.akamaized.net/images/65dc086b4f670a013853588f/original.png?utime=1708918891"
                          alt="閉じるボタン"
                          className=""
                          style={{
                            width: "auto",
                            borderRadius: 0,
                            maxWidth: "150%",
                            margin: "10px", // 根据需要调整边距
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 举例添加阴影效果
                          }}
                          height={50}
                          width={50}
                          role="presentation"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Main;
